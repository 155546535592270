#navbar a {
    text-decoration: none;
    align-items: center;
}

#navbar h3 {
    text-decoration: none;
    color: white;
    margin: 0;
}

#navbar p {
    text-decoration: none;
    color: white;
    font-weight: 100;
    margin: 0;
}