.App {
  /* text-align: center; */
  font-family: Arial, Helvetica, sans-serif;
}

span {
  font-size: 1.2rem;
  margin-right: 2%;
}

.ms-auto {
  text-align: right;
}

/* Containers */
.container-fluid {
  padding: 5% 15%;
}

/* Sections */
.colored-section {
  background-color: darkgray;
  color: white;
}

.white-section {
  background-color: #F6FFDE2f;
}

.light-section {
  background-color: #a9a9a920;
}

/* Nav Bar */
.navbar {
  background-color: darkgray;
  padding: 0 15%;
}

.navbar .container-fluid {
  padding: 1% 0;
}

.navbar-brand {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

.nav-link {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
}

/* Summary */
#summary {
  padding-top: 3%;
}

.center-section {
  text-align: center;
}

#summary h1, h6 {
  margin: 3% auto;
}

.profile-pic {
  width: 185px;
  border-radius: 100%;
}

/* Education */
.univ-logo {
  width: 50px;
}

.univ-item {
  margin: 2%;
}


.row-item {
  margin-bottom: 2%;
}

.project-title {
  color: black;
  text-decoration: none;
}

/* Footer */
#footer .container-fluid {
  padding: 2%;
}


@media screen and (max-width: 650px) {
  #summary {
    padding-top: 50px;
  }
}