/* src/components/Experience.css */

.experience {
  margin-bottom: 4rem;
  text-align: center;
}
/* 
#experience {
  text-align: center;
} */

.experience h6 {
  margin-top: 0;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Always three columns */
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 33%fr)); */
  gap: 1rem;
  margin-top: 1rem;
}

.project-grid-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  min-width: 250px;
}

.project-image {
  width: calc(100% - 20px);
  height: auto;
  border-radius: 8px;
  margin: 10px;
}

.project-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.project-info h5 {
  margin: 0;
}

.project-info a {
  color: #007acc;
  text-decoration: none;
  margin-top: 0.5rem;
}

.project-info a:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr)
  }
}

@media (max-width: 600px) {
  .projects-grid {
    grid-template-columns: repeat(1, 1fr)
  }
}